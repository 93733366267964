// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // TODO: Specify URI for your development environment
  appUrl: "https://test-__SLUG__.zonarsystems.net",
  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  coreEntityApiBaseUrl: "https://api-dev.zonarsystems.net",
  authEnv: "dev",
  name: "dev",
  auth: {
    clientID: "PcuP8cUrvhke1L9sWnGjzZwmOfBKuzHT",
    domain: "zonar-dev.auth0.com",
    audience: "http://apidev.zonarsystems.net/",
    // These come from the Core APIs as your application is built.
    applicationId: "2e4cef9d-364c-49ee-aa7a-37f93592b837",
    defaultZonarRole: "b07cb2aa-727d-4537-9282-cb50a2023779",
    useRefreshTokens: true,
  },
  datadog: {
    applicationId: "0fcf7c22-4f1c-40fc-9e26-46143a4f165f",
    clientToken: "pub0e79e7dac50f1491904e54954495bee9",
  },
  production: false,
  region: "NA",
  i18n: {
    supportedLanguages: ["en", "en-US", "de-DE"],
    defaultLanguage: "en-US",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
